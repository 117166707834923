@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "@fontsource/nunito";

body, html {
    padding: 0;
    margin: 0;
    min-height: 100%;
    height: 100%;
    width: 100%;
    background-color: #f8f8f8;
    z-index: 1;

    font-family: 'Nunito', sans-serif;
    font-size: 16pt;
    font-weight: 100;
    color: #002734;
    
    scroll-behavior: smooth;
}

mat-toolbar {
    background: none !important;
}


.content {
    margin-top: 100px;
    overflow: hidden;

    width: 100%;

    position: relative;
    z-index: 3;
}


.main{
    width: 92%;
    margin-left: auto;
    margin-right: auto;
}

h1, h2, h3 {
    font-weight: 100;
}

a, a:link, a:-webkit-any-link {
    color: #009cd2;
    text-decoration: none !important;
}

.main-first {
    display: block;
    min-height: calc(100% - 520px);
    padding-bottom: 96px;
}

.mat-card-header-text {
    margin: 0 8px !important;
}

.lr-padding {
    padding-left: 16px;
    padding-right: 16px;
}


h1 {
    font-size: 32px;
    color: #222D38;
}

h2 { font-size: 24px; }
h3 { font-size: 20px; }
h4 { font-size: 16px; }

@media screen and (min-width: 769px) {
    h1 { font-size: 48px; }
    h2 { font-size: 36px; }
    h3 { font-size: 28px; }
    h4 { font-size: 20px; }
}

@media screen and (min-width: 1025px) {
    .lr-padding {
        padding-left: max(16px, 15vw);
        padding-right: max(16px, 15vw);
    }
}

@media screen and (min-width: 1281px) {
    .main {
        min-width: 400px;
        width: 50%;
        max-width: 1000px;
    }
}
